/* You can add global styles to this file, and also import other style files */
@import "~primeng/resources/themes/bootstrap4-light-blue/theme.css";
@import "~primeicons/primeicons.css";
@import "~primeng/resources/primeng.css";

@font-face {
  font-family: montserrat-header;
  src: local("Montserrat"), url("./assets/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}
@font-face {
  font-family: montserrat-large;
  src: local("Montserrat"), url("./assets/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: montserrat-regular;
  src: local("Montserrat"), url("./assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}
html,body{
  height:100%;
}
.p-inputtext:enabled {
  &:focus {
    box-shadow: none;
    border-color: gray;
  }
}
.side-menu-div{
  border: none;
  font-weight: bold
}
.project-card {
  cursor: pointer;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin: .5rem
}
.project-card .p-card .p-card-content {
  padding: 0 0;
}
.project-card .p-card {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 100%;
}
.project-card:hover {
  cursor: pointer;
  box-shadow: 0 5px 5px 0px rgba(0,0,0,.2), 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);

}
.p-timeline.p-timeline-vertical .p-timeline-event-opposite {
  flex: none
}
.p-component {
   font-family:  montserrat-regular, montserrat-large, montserrat-header, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
   font-size: 1rem;
   font-weight: normal;
   color: #474747
 }
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #007bff;
  margin-right: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #0061d0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-button :not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.fa-blue{
  color: #007bff
}
.fa-grey{
  color: #a2a2a2
}
.fa-footer{
  color: #bebebe;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -o-transition: all .2s ease;
  -ms-transition: all .2s ease;
  transition: all .2s ease;
}
.fa-footer:hover{
  color: #a2a2a2;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -o-transition: all .2s ease;
  -ms-transition: all .2s ease;
  transition: all .2s ease;
}
.header-text{
  font-size: 50px;
  font-family: montserrat-header;
  color: #007bff;
  caret-color: transparent;
  cursor: pointer;
}
.title-text{
  font-size: 30px;
  font-family: montserrat-large;
  color: #606060;
  text-align: center;
  border-bottom: 1px solid #007bff;
  caret-color: transparent;
  width: 66.6667%;
}
.regular-text{
  font-size: 20px;
  font-family: montserrat-regular;
  color: #474747;
  caret-color: transparent;
}
.small-text{
  font-size: 15px;
  font-family: montserrat-regular;
  color: #474747;
  caret-color: transparent;
}
.hamburger-btn {
  display: none;
}
.navbar {
  position: fixed;
  padding: 10px;
  top: 81px;
  width: 100%;
  background-color: white;
  transition-duration: 0.3s;
  transition-property: top;

}
.title {
  text-align: center;
  width: 100%;
  position:fixed;
  background-color: white;
  padding: 10px;
  z-index: 2;
}
.link{
  color: #474747;
  cursor: pointer;
  text-decoration: none;
}
.sticky-header {
  position: fixed;
  width: 100%;
  padding: 10px;
  top: 0;
}
.main-content{
  padding: 58px 50px 50px;
  margin-top: 150px!important;
  caret-color: transparent;
}
.footer-content{
  padding: 10px 50px;
  border-top: #bebebe 1px solid;
}
.blur {
  background-color: rgba(0,0,0,0);
  position: fixed;

  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1
}
.blur.active{
  background-color: rgba(0,0,0,0.3);
  z-index: 5;
  left: 250px;
  transition: .5s;
}
.side-menu {
  position: fixed;
  left: 0;
  top: 0;
  overflow: auto;
  width: 250px;
  //border-right: 1px solid var(--surface-d);
  padding: 1rem;
  transition: transform .4s cubic-bezier(.05,.74,.2,.99);
  z-index: 3;
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  display: none;
}
.side-menu.active {
  -ms-transform: translateX(0);
  transform: translateX(0);
  z-index: 0;
}
.top-image {
  max-width: 100%;
  //max-height: 100%;
}
.top-image-container {
  width: 100%;
  padding-top: 50px;
  overflow: hidden;
}
span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

@media screen and (max-width: 584px){
  .small-text {
    font-size: 10px;
  }
  .regular-text {
    font-size: 15px;
  }
  .title-text {
    font-size: 25px;
  }
  .header-text {
    font-size: 40px;
  }
  .main-content{
    padding: 20px 20px;
  }
  .footer-content {
    padding: 10px 20px;
  }
  .menu-bar {
    display: none;
  }
  .hamburger-btn {
    display: inherit;
  }
  .side-menu {
    display: block;
  }
  .top-image {
    display: none;
  }
  .top-image-container {
    padding-top: 70px;
  }
  .navbar {
    display: none;
  }
  .title {
    z-index: 2;
  }
}
@media screen and (max-width: 441px){
  .main-content{
    margin-top: 150px!important;
    caret-color: transparent;
  }
  .top-image-container {
    display: block;
    height: 130px;
  }
}
@media screen and (min-width: 442px){
  .project-card {
    width: 45%;
  }
  .main-content{
    margin-top: 90px!important;
  }
}
@media screen and (min-width: 585px){
  .background-img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-size: 120%;
    height: 250px;
    background-position-y: 0
  }
  .main-content{
    margin-top: 150px!important;
  }
  .top-image-container {
    display: block;
    height: 350px;
  }
  .project-card {
    width: 45%;
  }
  .blur {
    display: none;
  }
}
@media screen and (min-width: 768px){
  .background-img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-size: 100%;
    height: 200px;
    background-position-y: 0px
  }
  .top-image-container {
    display: block;
    height: 350px;
  }
  .project-card {
    width: 30%;
  }
}
@media screen and (min-width: 992px){
  .background-img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-size: 80%;
    height: 250px;
    background-position-y: 0px
  }
  .sticky-header {
    position:fixed;
    width: 66.6667%;
    top: 0;
  }
  .navbar {
    top: 81px;
    width: 66.6667%;
  }
  .top-image-container {
    display: block;
    height: 400px;
    padding-top: 80px;
  }
  .title {
    width: 66.6667%;
  }
  .project-card {
    width: 45%;
  }
}

@media screen and (min-width: 1200px){
  .background-img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-size: 70%;
    height: 250px;
    background-position-y: 0px
  }
  .top-image-container {
    display: block;
    height: 400px;
    padding-top: 0;
  }
  .project-card {
    width: 30%;
  }
}

@media screen and (min-width: 1500px){
  .background-img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-size: 70%;
    height: 250px;
    background-position-y: -100px
  }
  .top-image-container {
    display: block;
    height: 400px;
    padding-top: 0px;
  }
}

